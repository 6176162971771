<template>
    <div class="px-4 text-14 pb-5">
        <form class="p-fluid pt-2" @submit.prevent="">
            <div class="field single-upload">
                <label class="text-black text-14 roboto-medium">
                    Primary Logo  *
                </label>
                <single-upload-file 
                    :addition_class="'primary'" 
                    @responseURL="setLogoPrimary"
                    :current_file="form.logo_primary">
                </single-upload-file>
                <label class="mt-2 text-12 text-black opacity-60">
                    Format file: PNG atau SVG
                </label>
            </div>
            <div class="field single-upload">
                <label class="text-black text-14 roboto-medium">
                    Secondary Logo
                </label>
                <single-upload-file 
                    :addition_class="'secondary'" 
                    @responseURL="setLogoSecondary"
                    :current_file="form.logo_secondary">
                </single-upload-file>
                <label class="mt-2 text-12 text-black opacity-60">
                    Format file: PNG atau SVG
                </label>
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Nama SoF *
                </label>
                <InputText type="text" placeholder="Source of Fund Name" v-model="form.name" :disabled="form.is_submit" />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Type *
                </label>
                <Dropdown v-model="form.selected_type" :options="types" optionLabel="label" placeholder="Pilih Tipe" :disabled="form.is_submit" />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Category *
                </label>
                <Dropdown v-model="form.selected_category" :options="categories" optionLabel="value" placeholder="Pilih Kategori" :disabled="form.is_submit" />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Deskripsi *
                </label>
                <Textarea rows="5" placeholder="Deskripsi" v-model="form.description" :disabled="form.is_submit" />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Servis *
                </label>
                <Dropdown v-model="form.selected_service" :options="services" optionLabel="name" placeholder="Pilih Servis" :disabled="form.is_submit" />
            </div>
            <div class="field">
                <label class="text-black text-14 roboto-medium mb-3">
                    Status
                </label>
                <div class="flex align-items-center">
                    <InputSwitch v-model="form.status_toggle" :disabled="form.is_submit" />
                    <label class="text-black opacity-60 ml-2">
                        Aktif
                    </label>
                </div>
            </div>
            <div class="field">
                <label class="text-black text-14 roboto-medium mb-3">
                    Pin
                </label>
                <div class="flex align-items-center">
                    <InputSwitch v-model="form.enable_pin_toggle" :disabled="form.is_submit" />
                    <label class="text-black opacity-60 ml-2">
                        Enable
                    </label>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import SingleUploadFile from '@/components/SingleUploadFile.vue'

export default {
    props: ['data', 'services'],
    components: {
        SingleUploadFile,
    },
    data() {
        return {
            form: {
                id: null,
                logo_primary: '',
                logo_secondary: '',
                name: '',
                description: '',
                selected_service: {},
                service_name: '',
                selected_type: {},
                type: '',
                selected_category: {},
                category: '',
                status_toggle: false,
                status: 0,
                enable_pin_toggle: false,
                enable_pin: 0,
                is_submit: false,
            },
            types: [
                {label: 'A: Binding', value: 'A'},
                {label: 'B: Non Binding', value: 'B'},
            ],
            categories: [
                {value: 'EWALLET'},
                {value: 'BANK'},
            ],
        }
    },
    watch: {
        'form': {
            handler: function() {
                this.form.service_name = this.form.selected_service ? this.form.selected_service['name'] : ''
                this.form.type = this.form.selected_type ? this.form.selected_type['value'] : ''
                this.form.category = this.form.selected_category ? this.form.selected_category['value'] : ''
                this.$emit('updated', this.form)
            },
            deep: true,
        },
    },
    mounted() {
        if (this.data) {
            this.form = {...this.form, ...this.data}
            this.form.selected_service = this.services.find(obj => obj.name == this.form.service_name);
            this.form.selected_type = this.types.find(obj => obj.value == this.form.type);
            this.form.selected_category = this.categories.find(obj => obj.value == this.form.category);
            this.form.status_toggle = this.form.status ? true : false
            this.form.enable_pin_toggle = this.form.enable_pin ? true : false
        }
    },
    methods: {
        setLogoPrimary(url) {
            this.form.logo_primary = url
        },
        setLogoSecondary(url) {
            this.form.logo_secondary = url
        },
    },
}
</script>

<style lang="scss" scoped></style>
